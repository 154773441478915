.firstinpu {
  /* background: url("https://source.unsplash.com/featured?technology"); */
  width: 100%;
  /* height: 80px; */
  background-color: #eaeef3;
  padding: 20px 0px;
  /* border-radius: 10px; */
  /* overflow: auto; */
  /* display: flex; */
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
  /* text-align: center; */
}

.Form-all-view {
  background-color: white;
  margin: 20px 40px;
  padding-top: 20px;
  padding-bottom: 30px;
  opacity: 100px;
  box-shadow: 0 5px 15px rgba(32, 32, 32, .3);
}

.firstinpu .Form-all-view h2 {
  margin-left: 30px;
  margin-bottom: 20px;
}

.profileImage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileImage img {
  width: 130px;
  height: 130px;
  border-radius: 100px;
  margin-top: 20px;
}

.profileImage .prfilebtn {
  text-decoration: none;
  padding: 8px 30px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #1a6aff;
  color: white;
  border: 1px solid #1a6aff;
  font-size: medium;
  transition: 0.3s ease-in-out;
}

.profileImage .prfilebtn:hover {
  background-color: #f1f5fc;
  color: #1a6aff;
}

.first-name {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  /* background-color: #1a6aff; */
}

.width-both-name-email {
  width: 94%;
  display: flex;
  justify-content: space-between;
}

.width-name {
  width: 49%;
  /* background-color: red; */
}

.subject-name {
  color: black;
  font-size: 15px;
  margin-bottom: 10px;
}

#name {
  width: 100%;
  border: 0.1px solid gray;
  padding: 12px;
  border-radius: 8px;
}

#email {
  width: 100%;
  border: 0.1px solid gray;
  padding: 12px;
  border-radius: 8px;
}

.second-subject {
  /* margin: 0px 10px; */
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #29166f; */
}

.width-subject {
  width: 94%;
  /* background-color: #514c65; */
}

#subject {
  width: 100%;
  border: 0.11px solid gray;
  padding: 12px;
  border-radius: 8px;
}

#message {
  width: 100%;
  height: 160px;
  border: 0.1px solid gray;
  padding: 12px;
  border-radius: 8px;
}

.buttun-center {
  margin-top: 25px;
  margin-left: 38px;
  /* text-align: center; */
}

.buttun-center .submit {
  padding: 14px 43px;
  border-radius: 5px;
  margin: 5px;
  background-color: #1a6aff;
  color: white;
  border: 1px solid #1a6aff;
  font-size: medium;
  transition: 0.3s ease-in-out;
}

.buttun-center #reset {
  background-color: #fda600;
  border-color: #fda600;
}

.buttun-center #submite:hover {
  background-color: #f1f5fc;
  color: #1a6aff;
}

.buttun-center #reset:hover {
  background-color: #f1f5fc;
  color: #fda600;
}


/* a{
    text-decoration: none;
  }
  a:hover{
    color: white;
    background-color:grey;
    margin: 1px 1px;
    border-radius: 5px;
    padding: auto;
  } */


.succeess_modal {
  margin-top: 5px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.succeess_modal button {
  padding: 12px 43px;
  border-radius: 5px;
  margin-top: 20px;
  background-color: #00a0f8;
  color: white;
  border: 1px solid #00a0f8;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: large;
  transition: 0.3s ease-in-out;
}

.succeess_modal button:hover {
  background-color: #f1f5fc;
  color: #00a0f8;
}