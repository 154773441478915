*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: 0;
}
.app{
    display: flex;
}
 
.topbar_page{
    width: 100%;
    background-color: #eaeef3;
}
.link{
    text-decoration: none;
    color: inherit;
}