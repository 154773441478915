.topbar {
  width: 100%;
  height: 70px;
  /* background-color: rgb(71, 13, 13); */
  background-color: #00a0f8;
  position: sticky;
  top: 0;
  z-index: 998;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: darkblue;
  cursor: pointer;
}

.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: whitesmoke;
}
.butunforgomas{
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: whitesmoke;
  display: flex;
  /* border: 1px solid #7717b7; */
  background-color: #874DAF;
  border-radius: 5px;
  padding: 7px 7px 0px 7px;
  color: white;
}
.butunforgomas img{
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}